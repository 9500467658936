import React from 'react';
import './App.css';
import Survey from'./Survey'

export default class App extends React.Component {
  constructor(props) {
    super(props);

    console.log("constructor of app called");
  }

  render() {
    return (
      <div className="App">
        <Survey/>
      </div>
    );
  }
}
