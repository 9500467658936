import axios from 'axios';

// const BASE_URL = "http://localhost/exdimum-survey";
const BASE_URL = "https://quiz.exdimum.ameno.de/api";

// const IMAGE_URL = "http://localhost/exdimum-survey/images";
const IMAGE_URL = "https://quiz.exdimum.ameno.de/images";

export async function fetchAllSurveySubjects() {
  return axios.get(BASE_URL + "/survey-subject");
}

export async function editSurvey(subjectAId, subjectBId, selectedSubjectId) {
  return axios.post(BASE_URL + "/survey/edit", {
    "subject_a_id": subjectAId,
    "subject_b_id": subjectBId,
    "selected_subject_id": selectedSubjectId,
  });
}

export function getImageUrlOfSubject(subject) {
  return IMAGE_URL + "/" + subject.id + ".jpg";
}
// export async function fetchSurvey(subjectAId, subjectBId)
