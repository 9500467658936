import React from 'react';
import { fetchAllSurveySubjects, editSurvey, getImageUrlOfSubject } from './api';

export default class Survey extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      surveySubjects: [],
      surveyHistory: [],
      fetchingSubjects: true,
      disableButtons: false,
    }
  }

  componentDidMount() {
    fetchAllSurveySubjects().then(result => {
      if (result.data.length <= 1) {
        alert("Es gibt momentan keine Umfragedaten");
        return;
      }

      let currentSurvey = this.createRandomSurvey(result.data);
      this.setState({
        surveySubjects: result.data,
        fetchingSubjects: false,
        currentSurvey: currentSurvey,
      });
    }).catch(exception => {
      console.error(exception);
    }).finally(() => this.setState({ fetchingSubjects: false }))
  }

  render() {
    console.log("currentSurvey", this.state.currentSurvey);
    return (
      <div>
        <h1>Würdest du lieber...</h1>
        {
          this.state.fetchingSubjects ?
            <span>Loading...</span>
            :
            this.state.currentSurvey ?
              <div className="flex-container">
                <div className="flex-item-1">
                  <span class="image-layer" style={{"background-image": "url(" + getImageUrlOfSubject(this.state.currentSurvey.subjectA) + ")"}}>
                    <button
                      className="survey-button survey-button-left"
                      onClick={() => this.selectSurveySubjectButtonClicked(this.state.currentSurvey.subjectA)}
                      disabled={this.state.disableButtons}
                    >
                      {this.state.currentSurvey.subjectA.name}<p className="subtext">{this.state.currentSurvey.subjectA.description}</p>
                    </button>
                  </span>
                </div>
                <div className="flex-item-2">
                  <h2>oder</h2>
                </div>
                <div className="flex-item-3">
                  <span class="image-layer" style={{"background-image": "url(" + getImageUrlOfSubject(this.state.currentSurvey.subjectB) + ")"}}>
                    <button
                      className="survey-button survey-button-right"
                      onClick={() => this.selectSurveySubjectButtonClicked(this.state.currentSurvey.subjectB)}
                      disabled={this.state.disableButtons}
                    >

                      {this.state.currentSurvey.subjectB.name}<p className="subtext">{this.state.currentSurvey.subjectB.description}</p>
                    </button>
                  </span>
                </div>
              </div>
              :
              <p>Derzeit keine Umfrage verfügbar</p>
        }
        <h1>...überfluten lassen?</h1>
        <SurveyHistory
          key={Date.now()}
          surveys={this.state.surveyHistory}
        />
      </div>
    );
  }

  createRandomSurvey(surveySubjects) {
    let randomA = this.randomNumber(0, surveySubjects.length);

    console.log("creating random survey", surveySubjects);

    do {
      var randomB = this.randomNumber(0, surveySubjects.length);
    } while (randomA === randomB);

    return {
      subjectA: surveySubjects[randomA],
      subjectB: surveySubjects[randomB],
      selectedSubject: null,
      surveyResult: null,
    }
  }

  selectSurveySubjectButtonClicked(selectedSubject) {
    this.setState({ disableButtons: true });
    console.log("selected subject: ", selectedSubject);
    let currentSurvey = this.state.currentSurvey;
    currentSurvey.selectedSubject = selectedSubject;
    console.log("Survey history: ", this.state.surveyHistory);

    editSurvey(currentSurvey.subjectA.id, currentSurvey.subjectB.id, selectedSubject.id).then((result) => {
      console.log("Surveyresult: ", result);
      currentSurvey.surveyResult = result.data;
      if (this.state.surveyHistory.length > 10) {
        this.state.surveyHistory.pop();
      }
      this.state.surveyHistory.unshift(currentSurvey);


      this.setState({
        surveyHistory: this.state.surveyHistory,
      })
    }).catch((exception) => {
      console.error(exception);
    }).finally(() => {
      this.setState({
        disableButtons: false,
        currentSurvey: this.createRandomSurvey(this.state.surveySubjects),
      });
    });
  }

  randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
}


/**
 * Historic display of the survey results
 */
class SurveyHistory extends React.Component {
  constructor(props) {
    super(props);
    let surveyComponents = []
    props.surveys.forEach((survey, index) => {
      console.log("Survey: ", survey);
      let totalCount = parseInt(survey.surveyResult.count_a) + parseInt(survey.surveyResult.count_b);

      console.log(totalCount + " " + survey.surveyResult.count_a + " " + survey.surveyResult.count_a / totalCount);

      let subjectLeft;
      let percentLeft;
      let subjectRight;
      let percentRight;
      if (totalCount > 0) {
        subjectLeft = survey.subjectA;
        subjectRight = survey.subjectB;

        if (subjectLeft.id === survey.surveyResult.survey_subject_id_a) {
          percentLeft = (survey.surveyResult.count_a / totalCount * 100);
          percentRight = (survey.surveyResult.count_b / totalCount * 100);
        } else {
          percentLeft = (survey.surveyResult.count_b / totalCount * 100);
          percentRight = (survey.surveyResult.count_a / totalCount * 100);
        }

        let animateInClass = (index === 0)? "fade-in-anim" : "";

        surveyComponents.push(
          <div className={"survey-history-container flex-container-history " + animateInClass} key={subjectLeft.id + "" + subjectRight.id}>
            <span
              id={subjectLeft.id === survey.selectedSubject.id ? "selected" : null}
              className="survey-history flex-item-1 text-right"
            >
              ({subjectLeft.name}) <b>{this.formatPercentage(percentLeft)}</b>
            </span>
            <span>-</span>
            <span
              className="survey-history flex-item-3 text-left"
              id={subjectRight.id === survey.selectedSubject.id ? "selected" : null}
            >
              <b>{this.formatPercentage(percentRight)}</b> ({subjectRight.name}) 
            </span>
          </div>
        )
      }
    });

    this.state = {
      surveyComponents: surveyComponents
    }
  }

  render() {
    return (
      <div>
        {this.state.surveyComponents}
      </div>
    );
  }

  formatPercentage(number) {
    return Math.round(number) + "%";
  }
}